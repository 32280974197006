import spbw from "../../utils/spbw";

import cls from './footer.module.css';

function Footer() {
    return (
        <footer className={cls.footer}>
            <div className={cls.part_top}>
                <div className={spbw('container', cls.container)}>

                    <div className={cls.section}>
                        <h3 className={cls.section_header}>
                            Contact
                        </h3>
                        <div className={cls.section_text}>
                            <ul className={cls.list}>
                                <li>
                                    E-mail:{' '}
                                    <a
                                        href="mailto:admin@coirle.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        admin@coirle.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </footer>
    );
}

export default Footer;
